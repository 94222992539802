import { useEffect, useRef, useState } from 'react';

import { Checkbox } from '@att-bit/duc.components.checkbox';
import { Select } from '@att-bit/duc.components.select';
import { TextField, TextFieldType } from '@att-bit/duc.components.text-field'
import { TextArea } from '@att-bit/duc.components.text-area'
import { ReactGA, GA_EVENTS } from 'util/analytics'

import ReCAPTCHA from 'react-google-recaptcha'
import { COMPLETE } from 'store/contstants';
import { STATES, VALID_EMAIL } from 'store/form';
import { useDispatch, useSelector } from 'react-redux';
import { updateState } from 'store/slices/applicationSlice';
import { postData } from 'store/Api'
import { RadioButton, RadioGroup } from '@att-bit/duc.components.radio-group';
import { Modal, ModalSize } from '@att-bit/duc.components.modal';
import Breadcrumbs from 'components/Breadcrumbs';

import './ResolutionForm.scss'


const BASE_FIELDS = ['propertyName', 'propertyId', 'city', 'state', 'zip', 'submitterEmail', 'submitterFirstName', 'submitterLastName', 'submitterPhone', 'description']
const BULK_FIELDS = ['submitterFirstName', 'submitterLastName', 'title', 'submitterEmail', 'submitterPhone', 'portfolio', 'propertyName', 'accountNumber']
const OPTIONAL_FIELDS = ['accountNumber','totalPropertyUnitCount','unitCountPerCO']


const ResolutionForm = () => {
  const recaptchaRef = useRef()

  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [dispatchFeeConsentConfirmed, setDispatchFeeConsentConfirmed] = useState(false);
  const [fileTypes, setFileTypes] = useState([]);
  const { role, roleIndex, categoryIndex, category, subcategory } = useSelector(state => state.application.selection)

  const roles = useSelector(state => state.application.roles)
  const { fields } = categoryIndex > -1 ? roles[roleIndex].categories[categoryIndex] : { fields: ['ticketNumber'] }

  const roleName = roles[roleIndex].name
  const categories = roles[roleIndex].categories
  const categoryName = categories[categoryIndex]?.name

  const DEFAULT_STATE = {}

  if(subcategory?.formType === 'bulk') {
    BULK_FIELDS.forEach((prop) => {
      DEFAULT_STATE[prop] = { value: '', error: '' }
    })
  } else {

    BASE_FIELDS.concat(fields).forEach((prop) => {
      if (prop === 'attRepContacted') {
        if (subcategory.action === 'phone') {
          DEFAULT_STATE[prop] = { value: '', error: '' }
        }
      } else if (prop === 'ticketNumber') {
        if (categoryIndex === -1) {
          DEFAULT_STATE[prop] = { value: '', error: '' }
        }
      } else {
        DEFAULT_STATE[prop] = { value: '', error: '' }
      }
    })

  }

  

  if (subcategory && subcategory.dispatchFee === "yes") {
    DEFAULT_STATE['dispatchFee'] = { value: false, error: '' }
  }

  if (subcategory && subcategory.slowProcessing === "yes") {
    DEFAULT_STATE['slowProcessing'] = { value: true, error: '' }
  }

  const IS_LUID = subcategory?.subcategory === "LUID Review"
  if (IS_LUID) {
    DEFAULT_STATE['approvedAddress'] = { value: '', error: '' }
  }

  const SHOW_UNIT_FIELDS = subcategory?.showUnitFields === true

  const IMAGE_UPLOAD = subcategory?.imageUpload === "yes";
  useEffect(() => {
    if (IMAGE_UPLOAD) {
      setFileTypes([
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/webp"
      ]);
    } else {
      setFileTypes([
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/pdf",
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/webp"
      ]);
    }
  }, [IMAGE_UPLOAD]);

  const [formData, setFormData] = useState(DEFAULT_STATE)
  const [currentFiles, setCurrentFiles] = useState(null)
  const [fileError, setFileError] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const userData = categoryIndex > -1 ? {
    "requestType": "new",
    "submitterRole": role,
    "submitterRoleName": roleName,
    "category": category,
    "categoryName": categoryName,
    "subcategory": subcategory.subcategory,
  } : {
    "requestType": "existing",
    "submitterRole": role,
    "submitterRoleName": roleName,
  }

  const dispatch = useDispatch()

  const handleFieldChange = (e) => {
    handleInputChange(e.target.name, e.target.value)
  }

  const handleCheckbox = (value) => {
    handleInputChange('dispatchFee', value)
  }

  const handleRadio = (value) => {
    handleInputChange('approvedAddress', value)
  }

  const handleTextAreaChange = (e) => {
    handleInputChange(e.target.id, e.target.value)
  }

  const handleInputChange = (name, value) => {
    const current = {
      ...formData
    }
    current[name].value = value
    setFormData(current)
  }

  const validateForm = () => {

    const keys = Object.keys(formData)
    let valid = true

    const state = { ...formData }

    if(subcategory?.formType === 'bulk') {

      keys.forEach((key) => {
        const current = formData[key]
        if (current?.value === '') {
          state[key].error = 'Field required'
          valid = false
        } else if (key.toLowerCase().includes('submitterEmail') && !VALID_EMAIL.test(current?.value)) {
          state[key].error = 'Invalid email'
          valid = false
        } else {
          state[key].error = ''
        }
      })

    } else {

      keys.forEach((key) => {
        const current = formData[key]
        if (!OPTIONAL_FIELDS.includes(key)) {
  
          if (key === 'dispatchFee') {
            if (current.value === false) {
              state[key].error = 'Field required'
              valid = false
            } else {
              state[key].error = ''
            }
          } else if (key === 'approvedAddress') {
            if (current.value === '') {
              state[key].error = 'Field required'
              valid = false
            } else {
              state[key].error = ''
            }
          } else if (current.value === '') {
            state[key].error = 'Field required'
            valid = false
          } else if (key.toLowerCase().includes('email') && !VALID_EMAIL.test(current.value)) {
            state[key].error = 'Invalid email'
            valid = false
          } else {
            state[key].error = ''
          }
        }
      })
      
      if (subcategory && subcategory.fileRequired === "yes") {
        if (currentFiles === null) {
          setFileError('File required')
          valid = false
        } else {
          setFileError('')
        }
      }
  
      if (currentFiles !== null && currentFiles.length > 0) {

        var currentError = '';
  
        currentFiles.forEach((currentFile) => {
          let fileSizeInMB = currentFile.size / (1024 * 1024); // convert from bytes to MB
          if (fileSizeInMB > 10) {
            valid = false;
            currentError = 'File size exceeds 10 MB';
          }
        })
  
        setFileError(currentError)
  
        
      }

    }

    setFormData(state)

    return valid

  }

  const confirmDispatchConsent = () => {
    setDispatchFeeConsentConfirmed(true);
    setShowDispatchModal(false);
  }

  useEffect(() => {
    if (subcategory?.dispatchFee === "yes" && dispatchFeeConsentConfirmed && !showDispatchModal) {
      submitForm();
    }
    // eslint-disable-next-line
  }, [dispatchFeeConsentConfirmed, showDispatchModal]);

  const submitForm = async () => {

    if (loading) {
      return
    }

    if (validateForm()) {

      //show dispatch fee consent modal if dispatch fee is required and not already confirmed
      if (subcategory?.dispatchFee === "yes" && !dispatchFeeConsentConfirmed) {
        setShowDispatchModal(true);
        return;
      }

      const form = {}

      form['token'] = recaptchaRef.current.getValue()
      form['formType'] = subcategory?.formType ? subcategory.formType : 'default'

      Object.keys(formData).forEach((key) => {
        if (key === 'state') {
          form[key] = STATES[formData[key].value].abbreviation
        } else {
          form[key] = formData[key].value
        }
      })

      setLoading(true)

      const selectedRole = roles[roleIndex]
      const selectedCategory = categories[categoryIndex]

      postData({
        ...userData,
        ...form,
      }, currentFiles).then((response) => {

        ReactGA.event(GA_EVENTS.acc_form_submitted, {
          acc_role_id: selectedRole?.id,
          acc_role_name: selectedRole?.name,
          acc_category_id: selectedCategory?.id,
          acc_category_name: selectedCategory?.name,
          acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
          acc_subcategory_name: subcategory?.subcategory,
          acc_form_type: userData?.requestType,
          acc_form_city: form['city'],
          acc_form_state: form['state'],
          acc_form_propertyId: form['propertyId'],
          acc_form_propertyName: form['propertyName'],
        })

        setLoading(false)
        dispatch(updateState({
          view: COMPLETE,
          ticket: response.ticket.ticketNumber
        }))
      }).catch(err => {
        ReactGA.event(GA_EVENTS.acc_form_invalid, {
          acc_role_id: selectedRole?.id,
          acc_role_name: selectedRole?.name,
          acc_category_id: selectedCategory?.id,
          acc_category_name: selectedCategory?.name,
          acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
          acc_subcategory_name: subcategory?.subcategory,
          acc_error: err.message,
        })
        setErrorMessage(err.message || 'An error occurred, please try again.')
        setLoading(false)
      })

    } else {
      setErrorMessage('Please fix any invalid form fields.')
    }

  }

  return (
    <div className="form container">
      {showDispatchModal &&
        <Modal onClose={() => { setShowDispatchModal(false); }}
          size={ModalSize.MEDIUM}
          scrimZindex={30}
          closeAriaLabel={'close modal'}
          aria-label={'modal aria label'}
          aria-modal="true">
          <Modal.Header>Dispatch Fee Agreement</Modal.Header>
          <Modal.Body>
            <div className="type-base">
              By clicking confirm you agree to a $199 dispatch on demand fee per account number.
            </div>
            <div className="flex justify-end flex-column-reverse">
              <div className="button-wrap mar-r-xxs mar-l-xxs mar-t-xs">
                <button className="btn-secondary" onClick={() => setShowDispatchModal(false)}>Cancel</button>
              </div>
              <div className="button-wrap mar-r-xxs mar-l-xxs mar-t-xs">
                <button className="btn-primary" onClick={() => confirmDispatchConsent()}>Confirm</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
      {categoryIndex > -1 && <div className="row">
        <div className="grid-col mar-t-xs">
          <Breadcrumbs level={subcategory ? 3 : 1} />
        </div>
      </div>}

      <div className="row">
        <div className="grid-col-12 grid-col">
          <h4 className="heading-sm mar-b-md">Please fill out the form below so we can solve the issue:</h4>

           {formData.slowProcessing &&
            <h5 className="mar-b-md">Due to the unique nature of this case please allow at least 7-10 business days for resolution as we
              bring in various departments to assist in resolving your case.</h5>}
          
          <div className="pad-b-md-md pad-b-sm-lg pad-b-lg-sm">

          {subcategory?.formType === 'bulk' ? (
            <div>

              <div className="form-row">
                <div className="row">
                  <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                    <TextField
                      id="submitterFirstName"
                      value={formData["submitterFirstName"].value}
                      onChange={handleFieldChange}
                      maxLength={30}
                      name="submitterFirstName"
                      disabled={loading}
                      error={formData["submitterFirstName"].error.length > 0}
                      errorText={formData["submitterFirstName"].error}
                      labelText="Submitter first name"
                      type={TextFieldType.TEXT} />
                  </div>
                  <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                    <TextField
                      id="submitterLastName"
                      value={formData["submitterLastName"].value}
                      onChange={handleFieldChange}
                      maxLength={30}
                      name="submitterLastName"
                      disabled={loading}
                      error={formData["submitterLastName"].error.length > 0}
                      errorText={formData["submitterLastName"].error}
                      labelText="Submitter last name"
                      type={TextFieldType.TEXT} />
                  </div>
                </div>
              </div>   
              <div className="form-row">
                <TextField
                  id="submitterPhone"
                  value={formData["submitterPhone"].value}
                  onChange={handleFieldChange}
                  maxLength={20}
                  name="submitterPhone"
                  disabled={loading}
                  error={formData["submitterPhone"].error.length > 0}
                  errorText={formData["submitterPhone"].error}
                  labelText="Submitter phone"
                  type={TextFieldType.TEXT} />
              </div>
              <div className="form-row">
                <TextField
                  id="submitterEmail"
                  value={formData["submitterEmail"].value}
                  onChange={handleFieldChange}
                  maxLength={255}
                  name="submitterEmail"
                  disabled={loading}
                  error={formData["submitterEmail"].error.length > 0}
                  errorText={formData["submitterEmail"].error}
                  labelText="Submitter email"
                  type={TextFieldType.TEXT} />
              </div>

              <div className="form-row">
                <TextField
                  id="title"
                  value={formData["title"].value}
                  onChange={handleFieldChange}
                  maxLength={255}
                  name="title"
                  disabled={loading}
                  error={formData["title"].error.length > 0}
                  errorText={formData["title"].error}
                  labelText="Title"
                  type={TextFieldType.TEXT} />
              </div>

              <div className="form-row">
                <TextField
                  id="portfolio"
                  value={formData["portfolio"].value}
                  onChange={handleFieldChange}
                  maxLength={255}
                  name="portfolio"
                  disabled={loading}
                  error={formData["portfolio"].error.length > 0}
                  errorText={formData["portfolio"].error}
                  labelText="Portfolio (owner)"
                  type={TextFieldType.TEXT} />
              </div>

              <div className="form-row">
                <TextField
                  id="propertyName"
                  value={formData["propertyName"].value}
                  onChange={handleFieldChange}
                  maxLength={255}
                  name="propertyName"
                  disabled={loading}
                  error={formData["propertyName"].error.length > 0}
                  errorText={formData["propertyName"].error}
                  labelText="Property name(s)"
                  hintText="To be added to profile (may provide more than 1)"
                  placeHolder="To be added to profile (may provide more than 1)"
                  type={TextFieldType.TEXT} />
              </div>

              <div className="form-row">
                <TextField
                  id="accountNumber"
                  value={formData["accountNumber"].value}
                  onChange={handleFieldChange}
                  maxLength={255}
                  name="accountNumber"
                  disabled={loading}
                  error={formData["accountNumber"].error.length > 0}
                  errorText={formData["accountNumber"].error}
                  labelText="Bulk account number(s)"
                  placeHolder="To be added to profile (may provide more than 1; account number can be located on invoice)"
                  hintText="To be added to profile (may provide more than 1; account number can be located on invoice)"
                  type={TextFieldType.TEXT} />
              </div>

            </div>
          ) : (
            <div>
               {formData.dispatchFee && <div className="form-row">
              <Checkbox
                id="dispatchFee"
                name="dispatchFee"
                text="By checking this box, I agree to a $199 dispatch on demand fee per account number. "
                checked={formData["dispatchFee"].value}
                onChange={handleCheckbox}
                error={formData["dispatchFee"].error.length > 0}
                errorMessage={formData["dispatchFee"].error}
              />
            </div>}

            {formData.ticketNumber && <div className="form-row">
              <TextField
                id="ticketNumber"
                value={formData["ticketNumber"].value}
                onChange={handleFieldChange}
                maxLength={11}
                name="ticketNumber"
                placeHolder="000-000-000"
                labelText="Case number"
                disabled={loading}
                error={formData["ticketNumber"].error.length > 0}
                errorText={formData["ticketNumber"].error}
                type={TextFieldType.TEXT} />
            </div>}

            <div className="form-row">
              <TextField
                id="propertyName"
                value={formData["propertyName"].value}
                onChange={handleFieldChange}
                maxLength={50}
                name="propertyName"
                labelText="Property name"
                disabled={loading}
                error={formData["propertyName"].error.length > 0}
                errorText={formData["propertyName"].error}
                type={TextFieldType.TEXT} />
            </div>

            <div className="form-row">
              <TextField
                id="propertyId"
                value={formData["propertyId"].value}
                onChange={handleFieldChange}
                maxLength={50}
                name="propertyId"
                labelText="ACC Property ID"
                disabled={loading}
                error={formData["propertyId"].error.length > 0}
                errorText={formData["propertyId"].error}
                type={TextFieldType.TEXT} />
            </div>
            <div className="form-row">
              <div className="row">
                <TextField
                  id="city"
                  value={formData["city"].value}
                  onChange={handleFieldChange}
                  maxLength={30}
                  name="city"
                  labelText="City"
                  disabled={loading}
                  error={formData["city"].error.length > 0}
                  errorText={formData["city"].error}
                  type={TextFieldType.TEXT} />
              </div>
            </div>
            <div className="form-row">
              <div className="row">
                <div className="grid-col-7 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <div className="select-container">
                    <Select
                      id="state"
                      label="State"
                      value={formData["state"].value}
                      name="state"
                      disabled={loading}
                      errorMsg={formData["state"].error !== '' && formData["state"].error}
                      onChange={(selectState) => {
                        handleInputChange('state', selectState.selectedIndex)
                      }}
                      >
                      {STATES.map((state) => <Select.Option key={`state_${state.abbreviation}`} value={state.name} />)}
                    </Select>
                  </div>
                </div>
                <div className="grid-col-5 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <TextField
                    id="zip"
                    value={formData["zip"].value}
                    onChange={handleFieldChange}
                    maxLength={10}
                    name="zip"
                    labelText="ZIP"
                    disabled={loading}
                    error={formData["zip"].error.length > 0}
                    errorText={formData["zip"].error}
                    type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>
            <div className="form-row">
              <hr />
            </div>
            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <TextField
                    id="submitterFirstName"
                    value={formData["submitterFirstName"].value}
                    onChange={handleFieldChange}
                    maxLength={30}
                    name="submitterFirstName"
                    disabled={loading}
                    error={formData["submitterFirstName"].error.length > 0}
                    errorText={formData["submitterFirstName"].error}
                    labelText="Submitter first name"
                    type={TextFieldType.TEXT} />
                </div>
                <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <TextField
                    id="submitterLastName"
                    value={formData["submitterLastName"].value}
                    onChange={handleFieldChange}
                    maxLength={30}
                    name="submitterLastName"
                    disabled={loading}
                    error={formData["submitterLastName"].error.length > 0}
                    errorText={formData["submitterLastName"].error}
                    labelText="Submitter last name"
                    type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>
            <div className="form-row">
              <TextField
                id="submitterPhone"
                value={formData["submitterPhone"].value}
                onChange={handleFieldChange}
                maxLength={20}
                name="submitterPhone"
                disabled={loading}
                error={formData["submitterPhone"].error.length > 0}
                errorText={formData["submitterPhone"].error}
                labelText="Submitter phone"
                type={TextFieldType.TEXT} />
            </div>
            <div className="form-row">
              <TextField
                id="submitterEmail"
                value={formData["submitterEmail"].value}
                onChange={handleFieldChange}
                maxLength={255}
                name="submitterEmail"
                disabled={loading}
                error={formData["submitterEmail"].error.length > 0}
                errorText={formData["submitterEmail"].error}
                labelText="Submitter email"
                type={TextFieldType.TEXT} />
            </div>
            {SHOW_UNIT_FIELDS && <div className="form-row">
              <TextField
                id="totalPropertyUnitCount"
                value={formData["totalPropertyUnitCount"].value}
                onChange={handleFieldChange}
                maxLength={255}
                name="totalPropertyUnitCount"
                disabled={loading}
                error={formData["totalPropertyUnitCount"].error.length > 0}
                errorText={formData["totalPropertyUnitCount"].error}
                labelText="Total property unit count"
                type={TextFieldType.TEXT} />
            </div>}
            {SHOW_UNIT_FIELDS && <div className="form-row">
              <TextField
                id="unitCountPerCO"
                value={formData["unitCountPerCO"].value}
                onChange={handleFieldChange}
                maxLength={255}
                name="unitCountPerCO"
                disabled={loading}
                error={formData["unitCountPerCO"].error.length > 0}
                errorText={formData["unitCountPerCO"].error}
                labelText="Unit count per CO"
                placeHolder="Example: CO = 10 units; CO2 = 5 units; CO3 = 4 units. Total CO's 19"
                type={TextFieldType.TEXT} />
            </div>}
            <div className="form-row">
              <hr />
            </div>

            {formData["propertyContactName"] && <div className="form-row">
              <TextField
                id="propertyContactName"
                value={formData["propertyContactName"].value}
                onChange={handleFieldChange}
                maxLength={60}
                name="propertyContactName"
                disabled={loading}
                error={formData["propertyContactName"].error.length > 0}
                errorText={formData["propertyContactName"].error}
                labelText="Property contact name"
                type={TextFieldType.TEXT} />
            </div>}

            {formData["propertyContactEmail"] && <div className="form-row">
              <TextField
                id="propertyContactEmail"
                value={formData["propertyContactEmail"].value}
                onChange={handleFieldChange}
                maxLength={255}
                name="propertyContactEmail"
                disabled={loading}
                error={formData["propertyContactEmail"].error.length > 0}
                errorText={formData["propertyContactEmail"].error}
                labelText="Property contact email"
                type={TextFieldType.TEXT} />
            </div>}

            {formData["approvedAddress"] && <div className="form-row">
              <RadioGroup
                name="approvedAddress"
                uniqueId="approvedAddress"
                title="Did the property contact approve this address list?"
                disabled={loading}
                error={formData["approvedAddress"].error.length > 0}
                errorText={formData["approvedAddress"].error}
                value={formData["approvedAddress"].value}
                onChange={handleRadio}
                >
                <RadioButton value="Yes" />
                <RadioButton value="No" />
              </RadioGroup>
            </div>}


            {formData["customerName"] && <div className="form-row">
              <TextField
                id="customerName"
                value={formData["customerName"].value}
                onChange={handleFieldChange}
                maxLength={60}
                name="customerName"
                disabled={loading}
                error={formData["customerName"].error.length > 0}
                errorText={formData["customerName"].error}
                labelText="Customer name"
                type={TextFieldType.TEXT} />
            </div>}

            {formData["customerEmail"] && <div className="form-row">
              <TextField
                id="customerEmail"
                value={formData["customerEmail"].value}
                onChange={handleFieldChange}
                maxLength={255}
                name="customerEmail"
                disabled={loading}
                error={formData["customerEmail"].error.length > 0}
                errorText={formData["customerEmail"].error}
                labelText="Customer email"
                type={TextFieldType.TEXT} />
            </div>}

            {formData["customerTitle"] && <div className="form-row">
              <TextField
                id="customerTitle"
                value={formData["customerTitle"].value}
                onChange={handleFieldChange}
                maxLength={255}
                name="customerTitle"
                disabled={loading}
                error={formData["customerTitle"].error.length > 0}
                errorText={formData["customerTitle"].error}
                labelText="Customer title"
                type={TextFieldType.TEXT} />
            </div>}

            {formData["customerAddress"] && <div className="form-row">
              <TextField
                id="customerAddress"
                value={formData["customerAddress"].value}
                onChange={handleFieldChange}
                maxLength={100}
                name="customerAddress"
                disabled={loading}
                error={formData["customerAddress"].error.length > 0}
                errorText={formData["customerAddress"].error}
                labelText="Customer address &amp; unit number"
                type={TextFieldType.TEXT} />
            </div>}

            {formData["accountNumber"] && <div className="form-row">
              <TextField
                id="accountNumber"
                value={formData["accountNumber"].value}
                onChange={handleFieldChange}
                maxLength={20}
                name="accountNumber"
                disabled={loading}
                error={formData["accountNumber"].error.length > 0}
                errorText={formData["accountNumber"].error}
                labelText="Customer account number (optional)"
                type={TextFieldType.TEXT} />
            </div>}

            {(fields.length > 0 && fields[0] !== 'ticketNumber') && <div className="form-row">
              <hr />
            </div>}

            {formData["attRepContacted"] && <div className="form-row">
              <TextField
                id="attRepContacted"
                value={formData["attRepContacted"].value}
                onChange={handleFieldChange}
                maxLength={20}
                name="attRepContacted"
                disabled={loading}
                error={formData["attRepContacted"].error.length > 0}
                errorText={formData["attRepContacted"].error}
                labelText="Who at AT&T did you speak with?"
                type={TextFieldType.TEXT} />
            </div>}

            <div className="form-row">
              <TextArea
                id="description"
                input={formData["description"].value}
                name="description"
                disabled={loading}
                error={formData["description"].error.length > 0}
                errorText={formData["description"].error}
                onChange={handleTextAreaChange}
                maxLength={500}
                labelText="Description of issue"
                placeHolder="Please include who, what, when, where & why" />
            </div>

            <div className="form-row">
              {IMAGE_UPLOAD && <label className="formfield-label flex" htmlFor="file">To serve you faster, please include a photo of the issue</label>}
              {!IMAGE_UPLOAD && <label className="formfield-label flex" htmlFor="file">Choose a file to upload</label>}
              <div className="flex flex-items-center">
                <button onClick={() => {
                  document.getElementById('file').click()
                }} className="btn-primary btn-small">Select File(s)</button>
                <span className='file-label pad-l-xxs'>{currentFiles ? currentFiles.length + ` file${currentFiles.length > 1 ? 's' : ''} selected` : 'No files selected'}</span>
              </div>
              <input
                className='hide'
                type="file"
                accept={fileTypes.join(',')}
                id="file"
                name="file"
                multiple
                onChange={((e) => {
                  const files = document.getElementById('file').files
                  if (files.length > 0) {
                    setCurrentFiles([...files])
                  } else {
                    setCurrentFiles(null)
                  }
                })}
                disabled={loading}
                />
              {fileError.length > 0 && <div className='error'><div className='formfield-msg'>{fileError}</div></div>}
            </div>
            
            </div>
          )}

            <div className="form-row">
              <div className="pad-b-xs">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcFzUMpAAAAAO5TrQaycNzUvwo_bxZvOCDN0GYX"
                />
              </div>
              <hr />
            </div>

            {errorMessage.length > 0 && <div className="form-row">
              <p className="type-sm color-red-600">{errorMessage}</p>
            </div>}

            <div className="form-row">

              {loading === false && <button className='btn-primary' onClick={() => {
                submitForm()
              }}>Submit</button>}              

              {loading === true && <button className="btn-primary flex" type="button" disabled="disabled">
                <svg viewBox="0 0 43 43" focusable="false" className="loading-spinner">
                  <g fill="none">
                    <circle r="20" cx="21.5" cy="21.5" stroke="#878c94" strokeWidth="3" opacity=".25" />
                    <circle r="20" cx="21.5" cy="21.5" className="loading-spinner-outer" strokeDasharray="89,200" stroke="#009fdb" strokeWidth="3" strokeLinecap="round" />
                  </g>
                </svg>
                <span>Sending...</span>
              </button>}

            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default ResolutionForm