export async function fetchData() {
  const response = await fetch('/api/application');
  const json = await response.json();
  return json;
}

export async function postData (data = {}, files) {
  //convert to form data
  const formData = new FormData()
    
  for(const name in data) {
    formData.append(name, data[name]);
  }

  if(files) {
    if(files instanceof Array) {
      files.forEach( file => formData.append('file', file) )
    } else {
      formData.append('file', files)
    }
  }

  // Default options are marked with *
  const response = await fetch('/api/tickets', {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    // headers: {
      // 'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    // },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
    body: formData
  });

  if (!response.headers.get('content-type')?.includes('application/json')) {
    throw new Error('Unable to submit your ticket at this time, please try again.')
  }

  const json = await response.json();
  
  if(response.ok) {
    if (json instanceof Object === false) {
      // Proxy sometime returns html instead of server json response
      throw new Error(json.message || 'An error occurred, please try again.')
    }
    return json;
  } else {
    switch(response.status) {
      case 400: 
        throw new Error(json.message || 'Invalid data, please try again.')
      case 403: 
        throw new Error(json.message || 'Access denied.')
      default: 
        throw new Error(json.message || 'An error occurred, please try again.')
    }      
  }
}

const methods = {
  fetchData,
  postData
}

export default methods