import { useDispatch, useSelector } from "react-redux"
import { CATEGORIES } from 'store/contstants';

import HeadsetLogo from 'images/reskin/icon-headphone.png'
import HandshakeLogo from 'images/reskin/icon-handshake.png'
import BubbleLogo from 'images/reskin/icon-message.png'
import PhoneIcon from 'images/reskin/icon-city.png'

import './Breadcrumbs.scss'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { updateState } from 'store/slices/applicationSlice'

const getIcon = (iconIndex) => {
  switch (iconIndex) {
    case 1:
      return HandshakeLogo
    case 2:
      return BubbleLogo
    case 3:
      return PhoneIcon
    default:
      return HeadsetLogo
  }
}

const Breadcrumbs = ({ level = 3 }) => {

  const dispatch = useDispatch();
  const { roles } = useSelector(state => state.application)
  const { categoryIndex, roleIndex, subcategory } = useSelector(state => state.application.selection)
  const categories = roles[roleIndex].categories

  const history = useHistory()

  const goCategory = (e) => {
    e.preventDefault()
    history.push(`/${roles[roleIndex].slug}/new/${categories[categoryIndex].slug}`)
  }

  return (
    <div className="breadcrumbs-outer">
      <div className="acc-breadcrumbs">
        <a className="type-sm" onClick={ ()=>{
          dispatch(updateState({
            view: CATEGORIES
          }))
          history.push(`/${roles[roleIndex].slug}`)
        }
        }>
          {roles[roleIndex].name}
        </a>
        {categoryIndex > -1 && level > 2 && <>
          {subcategory !== null && <a href="/" className={`type-sm ${(subcategory === null ? ' disabled' : '')}`} role="button" onClick={goCategory}>
            {categories[categoryIndex].name}
          </a>}
        </>}
      </div>
      <div className="flex icon-headline">
        <div className="icon-image">
          <img className="icon" src={getIcon(roleIndex)} alt="Store" />
        </div>
        {subcategory === null && categories !== null && <h3 className="heading-lg font-regular">{categories[categoryIndex]?.name}</h3>}
        {subcategory !== null && <h3 className="heading-lg font-regular">{subcategory.subcategory}</h3>}
      </div>
    </div>

  )
}

export default Breadcrumbs